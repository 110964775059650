import main from "api/main";
import CebForm from "components/Form/CebForm";
import PatientCard, { PatientCardTitles } from "components/patient/PatientCard";
import useApi from "hooks/useApi";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  MenuItem,
  Select,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { useToasts } from "react-toast-notifications";
import { getCurrentUser } from "auth/authService";
import { useTranslation } from "react-i18next";

const Permissions = ({ role = {}, backHandler }) => {
  const { t } = useTranslation();
  const [clinicMode, setClinicMode] = useState(
    getCurrentUser().role !== "Admin" &&
      !getCurrentUser()?.workplace_type?.hospital_profile
  );
  const [permissions, setPermissions] = useState({});
  const [filteredPermissions, setFilteredPermissions] = useState({});
  const [collapsePermission, setCollapsePermission] = useState({});
  const [addedPermissions, setAddedPermissions] = useState([]);
  const [removedPermissions, setRemovedPermissions] = useState([]);
  const [currentPermissions, setCurrentPermissions] = useState({});
  const [filteredModule, setFilteredModule] = useState("all");

  const { addToast } = useToasts();

  //api
  const getPermissionList = useApi(main.getPermissionList);
  const addRolePermissions = useApi(
    clinicMode ? main.addRoleClinicPermissions : main.addRolePermissions
  );
  const removeRolePermissions = useApi(
    clinicMode ? main.removeRoleClinicPermissions : main.removeRolePermissions
  );
  const getRolePermissions = useApi(
    clinicMode ? main.getRoleClinicPermissions : main.getRolePermissions
  );
  const resetPermissions = useApi(
    clinicMode ? main.resetClinicPermissions : main.resetPermissions
  );
  const getAdminPermissions = useApi(
    clinicMode ? main.getAdminClinicPermissions : main.getAdminPermissions
  );

  useEffect(() => {
    if (role) {
      //get all permission list for admin and get admin permissions only for entity
      if (getCurrentUser().role === "Admin") getPermissionList.request(role.id);
      else getAdminPermissions.request(role.id);
      getRolePermissions.request(role.id);
    }
  }, [role, clinicMode]);

  //set permission list
  useEffect(() => {
    if (getCurrentUser().role === "Admin") {
      const clinicPermissions = JSON.parse(
        JSON.stringify(getPermissionList.data)
      );
      if (clinicPermissions?.["Patient"]?.["Patient"]) {
        clinicPermissions["Patient"]["Patient"] = [
          clinicPermissions["Patient"]["Patient"][0],
        ];
      }
      setPermissions(clinicMode ? clinicPermissions : getPermissionList.data);
      setFilteredPermissions(getPermissionList.data);
    } else if (getCurrentUser().role !== "Admin") {
      setPermissions(getAdminPermissions.data);
      setFilteredPermissions(getAdminPermissions.data);
    }
  }, [getAdminPermissions.data, getPermissionList.data, clinicMode]);

  useEffect(() => {
    setCurrentPermissions(getRolePermissions.data);
  }, [getRolePermissions.data]);

  const handleChangeFilteredModule = (moduleName) => {
    setFilteredModule(moduleName);
    if (moduleName === "all") setFilteredPermissions(permissions);
    else {
      setFilteredPermissions({ [moduleName]: permissions[moduleName] });
      setCollapsePermission((state) => ({ ...state, [moduleName]: true }));
    }
  };

  const permissionCheckboxes = (module, subModule) => {
    return (
      <Stack
        direction={"row"}
        width="100%"
        flexWrap="wrap"
        justifyContent="flex-start"
      >
        {permissions[module]?.[subModule]?.map((item) => (
          <Stack
            direction="row"
            key={item.id}
            alignItems="center"
            justifyContent={"center"}
            width={`${
              (module === "Order" ||
                subModule === "Admission Order" ||
                module === "Patient") &&
              "100%"
            }`}
            marginLeft="30px"
          >
            {module === "Order" || subModule === "Admission Order" ? (
              <Typography>{item.name.substr(4)}</Typography>
            ) : item.name === "Date of Admission" ? (
              <Typography>{`Admitted Patients`}</Typography>
            ) : item.name === "Date of Discharge" ? (
              <Typography>{`Discharged Patients`}</Typography>
            ) : module === "Patient" || module === "Extra Permissions" ? (
              <Typography>{item.name}</Typography>
            ) : (
              <Typography>
                {item.codename.includes("add")
                  ? t("Add")
                  : item.codename.includes("view")
                  ? t("View")
                  : item.codename.includes("change")
                  ? t("Edit")
                  : item.codename.includes("access")
                  ? t("Access")
                  : item.name}
              </Typography>
            )}
            <Box>
              <Checkbox
                onChange={(e) =>
                  onChangeCheckboxHandler(
                    e.target.checked,
                    item,
                    module,
                    subModule
                  )
                }
                checked={
                  currentPermissions?.[module]?.[subModule]?.find(
                    (el) => el.id === item.id
                  )
                    ? true
                    : false
                }
              />
            </Box>
          </Stack>
        ))}
      </Stack>
    );
  };

  //set current permissions, added permissions and removed permissions
  const onChangeCheckboxHandler = (checked, item, module, subModule) => {
    if (checked) {
      setCurrentPermissions((state) => ({
        ...state,
        [module]: {
          ...state[module],
          [subModule]: state?.[module]?.[subModule]
            ? [...state?.[module]?.[subModule], item]
            : [item],
        },
      }));

      setAddedPermissions((state) => [...state, item]);
      setRemovedPermissions((state) => state.filter((el) => el.id !== item.id));

      //Check view admission if any admission module selected
      if (module === "Admission" && subModule !== "Admission") {
        const viewAdmissionItem = permissions["Admission"]?.["Admission"]?.find(
          (el) => el.codename.includes("view")
        );
        if (
          !addedPermissions.find(
            (el) => el.codename === viewAdmissionItem.codename
          )
        ) {
          onChangeCheckboxHandler(
            true,
            viewAdmissionItem,
            "Admission",
            "Admission"
          );
        }
      }
    } else {
      setCurrentPermissions((state) => ({
        ...state,
        [module]: {
          ...state[module],
          [subModule]: state[module][subModule].filter(
            (el) => el.id !== item.id
          ),
        },
      }));
      setRemovedPermissions((state) => [...state, item]);
      setAddedPermissions((state) => state.filter((el) => el.id !== item.id));
    }
  };

  const selectAllHandler = (checked, module) => {
    const permArr = [];
    for (const subModule in permissions[module])
      permArr.push(...permissions[module][subModule]);
    if (checked) {
      setCurrentPermissions((state) => ({
        ...state,
        [module]: permissions[module],
      }));
      const newAddedPerm = [...new Set([...addedPermissions, ...permArr])];
      setAddedPermissions(newAddedPerm);
      setRemovedPermissions((state) =>
        state.filter((item) => !permArr.includes(item))
      );
    } else {
      const newCurrentPerm = { ...currentPermissions };
      delete newCurrentPerm[module];
      setCurrentPermissions(newCurrentPerm);
      const newRemovedPerm = new Set([...removedPermissions, ...permArr]);
      setRemovedPermissions([...newRemovedPerm]);
      setAddedPermissions((state) =>
        state.filter((item) => !permArr.includes(item))
      );
    }
  };

  const resetPermissionsHandler = async () => {
    const res = await resetPermissions.requestWithToast(
      t("Reset successfully"),
      role.id
    );
    if (res.ok) {
      getRolePermissions.request(role.id);
    }
  };

  const saveHandler = async () => {
    let res;
    if (addedPermissions.length > 0) {
      res = await addRolePermissions.request(role.id, {
        permissions: addedPermissions.map((item) => item.id),
      });
    }
    if (removedPermissions.length > 0) {
      res = await removeRolePermissions.request(role.id, {
        permissions: removedPermissions.map((item) => item.id),
      });
    }
    if (res.ok) {
      addToast(t("Saved successfully"), {
        appearance: "success",
        autoDismiss: true,
      });
      getRolePermissions.request(role.id);
      setAddedPermissions([]);
      setRemovedPermissions([]);
    }
  };

  const handleClincSwitch = (checked) => {
    setClinicMode(checked);
    setAddedPermissions([]);
    setRemovedPermissions([]);
    setFilteredModule("all");
    setCollapsePermission({});
  };

  return (
    <Stack gap={3}>
      <Stack direction={"row"} justifyContent="space-between">
        <Stack direction="row" alignItems={"center"} gap={2}>
          <Typography>{t("Role")}:</Typography>
          <Typography color="primary">{role.name}</Typography>
        </Stack>
        {(getCurrentUser()?.workplace_type?.hospital_profile ||
          getCurrentUser().role === "Admin") && (
          <Stack direction="row" alignItems={"center"} gap={2}>
            <Typography>{t("Clinics")}</Typography>
            <Switch
              checked={clinicMode}
              onChange={(e) => handleClincSwitch(e.target.checked)}
              inputProps={{ "aria-label": "controlled" }}
            />
          </Stack>
        )}
      </Stack>

      <Stack direction="row" justifyContent="space-between">
        {/* filter by module */}
        <Stack direction="row" alignItems={"center"} gap={2}>
          <Typography>{t("Filter by Module")}</Typography>
          <Select
            onChange={(e) => handleChangeFilteredModule(e.target.value)}
            value={filteredModule}
            style={{ width: "200px" }}
          >
            <MenuItem value="all">{t("All")}</MenuItem>
            {Object.keys(permissions).map((module) => (
              <MenuItem key={module} value={module}>
                {module}
              </MenuItem>
            ))}
          </Select>
        </Stack>

        {/* reset permissions button for Entity */}
        <Stack direction="row" gap={2} alignItems="center">
          {getCurrentUser()?.role !== "Admin" && (
            <Button
              variant="contained"
              color="primary"
              style={{
                width: 130,
                height: 46,
                borderRadius: 10,
                textTransform: "none",
                fontWeight: 500,
              }}
              onClick={resetPermissionsHandler}
            >
              {t("Reset")}
            </Button>
          )}
          <Button
            variant="outlined"
            color="primary"
            style={{
              width: 130,
              height: 46,
              borderRadius: 10,
              textTransform: "none",
              fontWeight: 500,
            }}
            onClick={backHandler}
          >
            {t("Back")}
          </Button>
        </Stack>
      </Stack>

      <Stack gap={1}>
        <PatientCardTitles
          items={[{ header: "Module Name" }, { header: "Permissions" }]}
        />
        {Object.keys(filteredPermissions).map((module) => (
          <Stack key={module}>
            <PatientCard
              data={{ moduleName: module }}
              items={[
                { keyName: "data.moduleName" },
                {
                  type: "element",
                  element: (
                    <Stack direction="row" gap={4} alignItems="center">
                      <Button
                        style={{
                          background: "#A5D2FF",
                          color: "#003468",
                          width: 140,
                          height: 36,
                          borderRadius: 10,
                          fontSize: 16,
                          fontWeight: 400,
                        }}
                        onClick={() =>
                          setCollapsePermission((state) => ({
                            ...state,
                            [module]: !state[module],
                          }))
                        }
                      >
                        {collapsePermission[module] ? t("Hide") : t("View")}
                      </Button>
                      <Stack direction="row" alignItems="center">
                        {t("select all")}
                        <Checkbox
                          onChange={(e) =>
                            selectAllHandler(e.target.checked, module)
                          }
                          checked={
                            JSON.stringify(permissions[module]) ===
                            JSON.stringify(currentPermissions[module])
                          }
                        />
                      </Stack>
                    </Stack>
                  ),
                },
              ]}
            />

            <Collapse in={collapsePermission[module]}>
              <Stack gap={1} mt={1}>
                {Object.keys(permissions?.[module]).map((subModule) => (
                  <PatientCard
                    key={subModule}
                    data={{
                      moduleName:
                        subModule === "Patient Columns"
                          ? "Patient List"
                          : subModule,
                    }}
                    items={[
                      { keyName: "data.moduleName" },
                      {
                        type: "element",
                        element: permissionCheckboxes(module, subModule),
                        // element: <div>test</div>,
                      },
                    ]}
                  />
                ))}
              </Stack>
            </Collapse>
          </Stack>
        ))}
      </Stack>

      <Stack direction={"row"} justifyContent="flex-end">
        <Button
          variant="contained"
          color="primary"
          style={{
            width: 145,
            height: 46,
            borderRadius: 10,
            textTransform: "none",
            fontWeight: 500,
          }}
          onClick={saveHandler}
          disabled={
            !Object.keys(addedPermissions).length &&
            !Object.keys(removedPermissions).length
          }
        >
          Save
        </Button>
      </Stack>
    </Stack>
  );
};

export default Permissions;
